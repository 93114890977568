body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  border-bottom: 2px solid lightseagreen;
  padding: 10px;
}

.header {
  font-weight: bolder;
  text-transform: uppercase;
  margin: 5px 10px;
}

.value {
  font-size: 1.2em;
}

button {
  border: none;
  padding: 5px 10px;
}

button.fullWidth {
  width: 90%;
}

button {
  font-weight: bolder;
  background-color: darkblue;
  color: white;
  text-transform: uppercase;
  margin: 5px;
  padding: 20px;
}

input {
  font-size: 1.2em;
  height: 50px;

}

button:disabled {
  background-color: gray;

}